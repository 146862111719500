import './button.css';

function Button(props) {
    var classes = "ripple ";
    classes = props.large ? (classes + "large ") : classes;
    classes = props.borderless ? (classes + "borderless ") : 
    classes = props.className ? (classes + props.className) : classes;
    
    var onClickFunction = undefined;

    if (props.href) {
        onClickFunction = () => {window.location.href=props.href};
    } else if (props.onClick) {
        onClickFunction = props.onClick;
    }

    return (
        <button className={classes} onClick={ onClickFunction }>{props.children}</button>
    );
}

export default Button;