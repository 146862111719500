const NAME_REGEX = /^(?!-)[a-zA-Z-]*[a-zA-Z]$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PHONE_REGEX = /^\d{10}$/;
const TEXT_REGEX = /^[A-Za-z\d\s]+$/;
const URL_REGEX = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
const US_ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;


var validator = {
    name: function(name) {
        return NAME_REGEX.test(name);
    },
    email: function(email) {
        return EMAIL_REGEX.test(email);
    },
    phone: function(phone) {
        return PHONE_REGEX.test(phone);
    },
    text: function(text) {
        return TEXT_REGEX.test(text);
    },
    value: function(value) {
        return value !== undefined && value.trim() !== '';
    },
    url: function(value) {
        return URL_REGEX.test(value);
    },
    zip: function(value) {
        return US_ZIP_REGEX.test(value);
    }
};
 
export default validator;