import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Home.css';
import Banner from './../../sections/banner/banner';
import Header from './../../sections/header/header';
import Pricing from './../../sections/pricing/pricing';
import Contact from './../../sections/contact/contact';
import Footer from './../../sections/footer/footer';
import Products from './../../sections/products/products';

const homePage =
  <div className="home">
    <Header home></Header>
    <Banner></Banner>
    <Products></Products>
    <Pricing></Pricing>
    <Contact></Contact>
    <Footer></Footer>
  </div>;


function Home() {
  return (homePage);
}

export default Home;