
import { Modal } from 'react-bootstrap';
import './progress.css';

function Progress(props) {
    return (
        <>
            <Modal show={props.show} centered size="xl">
                <Modal.Header></Modal.Header>
                <Modal.Body>

                    <div className='cargando' />

                </Modal.Body>
            </Modal>
        </>
    )
}

export default Progress;