import './text.css';

function Text(props) {
    var classes = "text ";
    classes = props.thin ? (classes + "thin ") : classes;
    classes = props.light ? (classes + "light ") : classes;
    classes = props.para ? (classes + "para ") : classes;
    classes = props.heavy ? (classes + "heavy ") : classes;
    classes = classes + props.className;

    var styles = props.color ? {color: props.color} : {};

    return (
        <>
         <span className={classes} style={styles}>{props.children}</span>
        </>
    );
}

export default Text;