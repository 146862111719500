import { Container, Row, Col } from "react-bootstrap";
import Text from "../../views/text/text";
import Title from "../../views/title/title"
import Card from "../../views/card/card";
import hsaImage from './images/hsa.png';
import sadcImage from './images/sadc.png';
import hcaImage from './images/hhc.png';
import './products.css';
import './products-sm.css';
import Button from "../../views/button/button";
import Highlight from "../../views/highlight/highlight";
function Products() {
    return (
        <>
            <Container className="pt-5-lg product" id="products">
                <Row className="title-row">
                    <Col md="6" className="main-head">
                        <section><Title> We focus on your business <strong>helping your team be the best</strong></Title></section>
                        <section><Text thin>Different companies have different needs, therefore we offer three separate products for three types of healthcare companies.</Text></section>
                    </Col>
                </Row>
                <Row className="justify-content-center product-list">
                    <Col md={{span: 6, order: 3 }} lg="4" className="margin-botton-sm mt-md-3 mt-lg-0">
                        <Card>
                            <div className="product-item">
                                <section className="image-section"><img src={hsaImage} alt="location product"></img></section>
                                <section><Title type="sub">Software <Highlight>For Healthcare Staffing Agencies</Highlight></Title></section>
                                <section className="text-section">
                                <Text para>With OnTime, your employees can easily log their attendance with our mobile app making it easy to keep track of attendance, run payroll and manage your invoices all in one place. </Text>
                                </section>
                                <section className="more-btn-section">
                                    <Button borderless href="/hsa/">Learn More</Button>
                                </section>
                            </div>
                        </Card>
                    </Col>
                    <Col md={{span: 6, order: 1 }} lg="4" className="margin-botton-sm">
                        <Card>
                            <div className="product-item">
                                <section className="image-section"><img src={sadcImage} alt="schedule feature"></img></section>
                                <section><Title type="sub">Software <Highlight>For Adult Day Care Centers</Highlight></Title></section>
                                <section className="text-section">
                                <Text para>Our  software helps you eliminate the paperwork involved with maintaining an adult day care, centralizing attendance, authorizations and records required to stay compliant.</Text>
                                </section>
                                <section className="more-btn-section">
                                    <Button borderless href="/sadc/">Learn More</Button>
                                </section>
                            </div>
                        </Card>
                    </Col>
                    <Col md={{span: 6, order: 2 }} lg="4" className="margin-botton-sm">
                        <Card>
                            <div className="product-item">
                                <section className="image-section"><img src={hcaImage} alt="billing product"></img></section>
                                <section><Title type="sub">Software <Highlight>For Home Care Agencies</Highlight></Title></section>
                                <section className="text-section">
                                <Text para>Running a home care agency is not easy. With OnTime, you can cover multiple cases and keep track of their authorizations, schedules,  EVV, HR compliance and <br /> billing.</Text>
                                </section>
                                <section className="more-btn-section">
                                    <Button borderless href="/hca/">Learn More</Button>
                                </section>
                            </div>
                        </Card>
                    </Col>
                </Row>


            </Container>
        </>
    );
}

export default Products;