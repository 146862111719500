import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './../../index.css';
import Header from '../../sections/header/header';
import Footer from '../../sections/footer/footer';
import Stripeo from '../../sections/stripe/stripeo';

const paymentPage =
    <>
      <Header></Header>
      <div className="content">
        <Stripeo/>
      </div>
      <Footer></Footer>
    </>


function Payment() {
  return (paymentPage);
}

export default Payment;