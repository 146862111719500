import { Carousel, Col, Container, Row } from "react-bootstrap";
import Card from "../../views/card/card";
import Title from "../../views/title/title";
import Text from "../../views/text/text";
import Button from '../../views/button/button';
import './pricing.css'
import './pricing-sm.css'
import { useState } from "react";

function Pricing() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return(
        <>
            <Container className="pricing" id="pricing" >
                <Row className="pricing-title">
                    <section><Title>Our pricing structure<strong>is inclusive of all company sizes.</strong></Title></section>
                    <section><Text thin>Need more details about pricing? Contact us!</Text></section>
                </Row>
                <Row className="d-none d-md-block d-lg-none">

                    <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
                        <Carousel.Item>
                            <Card>
                                <section className="price-model"><Title type="sub"><strong>Startup</strong></Title></section>
                                <section><Text thin para>0 - 10 active patients or employees/mo</Text></section>
                                <section className="pricing-features">
                                    <ul>
                                        <li><Text thin>Mobile Attendance</Text></li>
                                        <li><Text thin>Scheduler</Text></li>
                                        <li><Text thin>Payroll Processing</Text></li>
                                        <li><Text thin>Billing</Text></li> 
                                    </ul>
                                </section>
                                <section className="price-section">
                                    <span className="price-figure"><sup className="price-sign">$</sup>99</span>
                                    <span className="price-month">/month</span>
                                </section>
                                <section className="fine-print">
                                    A registration fee is required
                                </section>
                                <section className="price-button">
                                    <Button large href="/register">Get Started</Button>
                                </section>
                            </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Card>
                                <div className="price-highlight">
                                    <section className="price-model"><Title type="sub"><strong>Workforce</strong></Title></section>
                                    <section><Text thin color="#FFFFFF">11-500 active patients or employees/mo</Text></section>
                                </div>
                                <section className="pricing-features">
                                    <ul>
                                        <li><Text thin>Startup Basics</Text></li>
                                        <li><Text thin>Gold Level Support</Text></li>
                                        <li><Text thin>Benefits Module</Text></li>
                                        <li><Text thin>Custom Reports</Text></li>
                                    </ul>
                                </section>
                                <section className="price-section">
                                    <span className="price-figure"><sup className="price-sign">$</sup>10</span>
                                    <span className="price-month">/active resource</span>
                                </section>
                                <section className="fine-print">
                                    A registration fee is required
                                </section>
                                <section className="price-button price-btn-highlight">
                                    <Button large href="/register">Get Started</Button>
                                </section>
                            </Card>
                        </Carousel.Item>

                        <Carousel.Item>
                            <Card>
                                <section className="price-model"><Title type="sub"><strong>Enterprise</strong></Title></section>
                                <section><Text thin para>for Custom System Licence</Text></section>
                                <section className="pricing-features">
                                    <ul>
                                        <li><Text thin>Branded System</Text></li>
                                        <li><Text thin>Custom Web App</Text></li>
                                        <li><Text thin>Custom Mobile App</Text></li>
                                        <li><Text thin>24x7 Production Support</Text></li> 
                                    </ul>
                                </section>
                                <section className="price-section custom-price-section">
                                    <span className="price-figure">Custom Pricing</span><br />
                                    <span className="price-month">for Custom System Licence</span>
                                </section>
                                <section className="price-button">
                                    <Button large href="#contact">Contact Us</Button>
                                </section>
                                
                            </Card>
                        </Carousel.Item>
                    </Carousel>

                </Row>
                <Row className="justify-content-center d-lg-flex d-md-none">
                    <Col lg="4" className="margin-botton-sm">
                        <Card>
                            <section className="price-model"><Title type="sub"><strong>Startup</strong></Title></section>
                            <section><Text thin para>0 - 10 active patients or employees/mo</Text></section>
                            <section className="pricing-features">
                                <ul>
                                    <li><Text thin>Mobile Attendance</Text></li>
                                    <li><Text thin>Scheduler</Text></li>
                                    <li><Text thin>Payroll Processing</Text></li>
                                    <li><Text thin>Billing</Text></li> 
                                </ul>
                            </section>
                            <section className="price-section">
                                <span className="price-figure"><sup className="price-sign">$</sup>99</span>
                                <span className="price-month">/month</span>
                            </section>
                            <section className="fine-print">
                                A registration fee is required
                            </section>
                            <section className="price-button">
                                <Button large href="/register">Get Started</Button>
                            </section>
                        </Card>
                    </Col>
                    <Col lg="4" className="margin-botton-sm">
                        <Card>
                            <div className="price-highlight">
                                <section className="price-model"><Title type="sub"><strong>Workforce</strong></Title></section>
                                <section><Text thin color="#FFFFFF">11-500 active patients or employees/mo</Text></section>
                            </div>
                            <section className="pricing-features">
                                <ul>
                                    <li><Text thin>Startup Basics</Text></li>
                                    <li><Text thin>Gold Level Support</Text></li>
                                    <li><Text thin>Benefits Module</Text></li>
                                    <li><Text thin>Custom Reports</Text></li>
                                </ul>
                            </section>
                            <section className="price-section">
                                <span className="price-figure"><sup className="price-sign">$</sup>10</span>
                                <span className="price-month">/active resource</span>
                            </section>
                            <section className="fine-print">
                                A registration fee is required
                            </section>
                            <section className="price-button price-btn-highlight">
                                <Button large href="/register">Get Started</Button>
                            </section>
                        </Card>
                    </Col>
                    <Col lg="4" className="margin-botton-sm">   
                        <Card>
                            <section className="price-model"><Title type="sub"><strong>Enterprise</strong></Title></section>
                            <section><Text thin para>for Custom System Licence</Text></section>
                            <section className="pricing-features">
                                <ul>
                                    <li><Text thin>Branded System</Text></li>
                                    <li><Text thin>Custom Web App</Text></li>
                                    <li><Text thin>Custom Mobile App</Text></li>
                                    <li><Text thin>24x7 Production Support</Text></li> 
                                </ul>
                            </section>
                            <section className="price-section custom-price-section">
                                <span className="price-figure">Custom Pricing</span><br />
                                <span className="price-month">for Custom System Licence</span>
                            </section>
                            <section className="price-button">
                                <Button large href="#contact">Contact Us</Button>
                            </section>
                            
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>
    );
}

export default Pricing;