import { useState } from "react";
import AxiosClient from "../../core/rest/client";
import validator from "../../core/utils/validators";
import Button from "../../views/button/button";
import Input from "../../views/input/input";
import ResultDialog from "../../views/result-dialog/result-dialog";
import Text from "../../views/text/text";
import Title from "../../views/title/title";
import Progress from "../progress/progress";
import './contact.css';
import './contact-sm.css';
import './contact-md.css';
import toast from 'react-hot-toast';
const { Container, Row, Col, Form } = require("react-bootstrap");

function Contact(props) {

    const [inputVals, setInputVals] = useState({
        firstName: '',
        lastName: '',
        email: '',
        enquiryType: '',
        message: ''
    });

    const [errorState, setErrorState] = useState({
        firstName: false,
        lastName: false,
        email: false,
        message: false,
        enquiryType: ''
    });
    const [isInSubmitProgress, setIsInSubmitProgress] = useState(false);
    
    const contactButtonClickHandler = () => {

        if(isInSubmitProgress)
            return;

        if (props.orgType !== undefined) {
            inputVals.enquiryType = props.orgType;
        }

        for (var key in inputVals) {
            if (key === 'firstName' || key === 'lastName') {
                errorState[key] = !validator['name'](inputVals[key]);
            } else if (key === 'email') {
                errorState[key] = !validator['email'](inputVals[key]);
            } else if (key === 'message') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'enquiryType') {
                errorState[key] = inputVals[key] === '';
            } 
        }

        setErrorState({ 
            ...errorState
        });
        for (key in errorState) {
            if (errorState[key]) {
                return;
            }
        }

        setIsInSubmitProgress(true);

        let axiosClient = new AxiosClient();
        axiosClient.postRequest(process.env.REACT_APP_API_URL +"/contact-us", inputVals, onSubmissionSuccess, onSubmissionFailure);
        
    };


    const onSubmissionSuccess = (response) => {
        setInputVals({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            enquiryType: ''
        });
        setIsInSubmitProgress(false);
        
        if (response.data === undefined) {
            toast.error("Failed to send contact message");
            return;
        }

        toast.success("Thank you! We will get back to you as soo as possible");
    };

    const onSubmissionFailure = (error) => {
        toast.error("Failed to send contact message");
        setInputVals({
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            enquiryType: ''
        });
        setIsInSubmitProgress(false);
    };

    const changeHandler = (e) => {
        inputVals[e.target.name] = e.target.value;
        if(errorState[e.target.name] !== undefined) {
            errorState[e.target.name] = false;
            setErrorState({ 
                ...errorState
            });
        }
    };

    const validateFn = (e) => {
        errorState[e.target.name] = !validator[e.target.attributes['data-validation-type'].value](e.target.value);
        setErrorState({ 
            ...errorState
        });
    };


    return (
        <>
            <Container className="contact-section" fluid id="contact">
                <Row className="justify-content-md-end align-items-center">
                    <Col lg="4" md="5">
                        <section><Title> Need help getting started?<strong>Contact us!</strong></Title></section>
                        <section><Text thin>Fill out the form letting us know what your question is and we’ll get back to you with answers as soon as possible.</Text></section>
                    </Col>
                   
                    <Col xl="6" md="7" className="contact-form">
                        <Row className="py-2">
                            <Col lg="3"><Input placeholder="First Name" type="text" name="firstName" 
                                onChange={changeHandler} onBlur={validateFn}
                                errormessage="Please enter a valid First Name"
                                showerror={+errorState.firstName} data-validation-type="name" value={inputVals.firstName}></Input>
                            </Col>
                            <Col lg="3" className="small-screens-margin"><Input placeholder="Last Name" type="text" name="lastName" 
                                onChange={changeHandler} onBlur={validateFn}
                                errormessage="Please enter a valid Last Name"
                                showerror={+errorState.lastName} data-validation-type="name" value={inputVals.lastName}></Input>
                            </Col>
                        </Row>
                        <Row className="py-2">
                            <Col lg="6"><Input placeholder="Email Address" type="email" name="email" 
                                    onChange={changeHandler} onBlur={validateFn}
                                    errormessage="Please enter a valid Email address"
                                    showerror={+errorState.email} data-validation-type="email" value={inputVals.email}></Input>
                            </Col>
                        </Row>

                        {(props.orgType === undefined) && <Row className="py-2">
                            <Col lg="6">
                                <Form.Select size="lg" onChange={changeHandler} name="enquiryType" isInvalid={errorState.enquiryType}>
                                    <option value="">Select Org Type</option>
                                    <option value="HSA">Healthcare Staffing Agency</option>
                                    <option value="SADC">Social Adult Day Care Center</option>
                                    <option value="HHA">Home Healthcare Agency </option>
                                </Form.Select>
                            </Col>
                        </Row> }
                        <Row className="py-2">
                            <Col lg="6"><Input placeholder="Message" type="textarea" name="message" 
                                        onChange={changeHandler} onBlur={validateFn}
                                        errormessage="Please enter your message"
                                        showerror={+errorState.message} data-validation-type="value" value={inputVals.message}></Input>
                            </Col>
                        </Row>

                        <Row className="py-2">
                            <Col lg="6"><Button className="float-md-end" onClick={ contactButtonClickHandler }>Submit</Button></Col>
                        </Row>
                        <ResultDialog></ResultDialog>
                    </Col>
                </Row>
            </Container>

            <Progress show = {isInSubmitProgress}></Progress>
            
        </>
    );
}

export default Contact;