import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './Demo.css';
import DemoForm from '../../sections/demo-form/DemoForm';
import Header from '../../sections/header/header';
import Footer from '../../sections/footer/footer';

const demoPage = 
      <>
        <Header></Header>
        <div className="content">
          <DemoForm />
        </div>
        <Footer></Footer>
      </>;

function Demo() {
  return (demoPage);
}

export default Demo;