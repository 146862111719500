import { Col, Container, Row } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import Checkout from "./checkout";
import Text from "../../views/text/text";
import Title from "../../views/title/title";
import './stripeo.css';
import successImage from './images/check.png';
import pendingImage from './images/stopwatch.png';
import failedImage from './images/close.png';
import invalidImage from './images/minus.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);



function Stripeo() {
  const {state} = useLocation();
  let paymentStatus = 0;

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#005201',
      colorText: '#4c4d57',
    },
  };
  
  const options = {
    appearance,
    ...state
  };  


  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  const redirectStatus = new URLSearchParams(window.location.search).get(
    "redirect_status"
  );

    
  if (clientSecret && redirectStatus) {

    switch (redirectStatus) {
      case "succeeded":
        paymentStatus = 1;
        break;
      case "processing":
      case "pending":
        paymentStatus = 2;
        break;
      case "requires_payment_method":
        paymentStatus = -1;
        break;
      default:
        paymentStatus = -1;
        break;
    }
    const storageClientSecret = sessionStorage.getItem("pi_temp_code");
    if (clientSecret !== storageClientSecret) {
      paymentStatus = -2;
    } 
  } else {
    sessionStorage.setItem("pi_temp_code", options.clientSecret);
  }
   
  return (
      <>
        { (paymentStatus === 0) &&
         (<Container className="payment-container">
            <Row>
              <section><Title>Card <strong>Information</strong></Title></section>
              <section><Text thin>Please provide your payment information</Text></section>
            </Row>
            <Row className="payment-details-container">
              <Col>
                <div><Text thin className="checkout-price-title">Ontime Subscription Fee (One-time Payment)</Text></div>
                <div><Text heavy className="checkout-price-text">$99.00</Text></div>
                {/* <div className="checkout-item-area"><Text>Software Subscription Fee  <span className="checkout-item-spacing"></span>$900.00</Text></div> */}
                <div className="checkout-subscribtion-area">
                  <Text thin className="checkout-subscribtion-text">This fee will cover your onboarding time and the set-up of your account in our system. <br /> This charge will only be made once and it is non-refundable.</Text>
                  <br />
                  <Text thin className="checkout-subscribtion-text">OnTime ITS subscribtion pricings are completely usage based.</Text>
                  <Text thin className="checkout-subscribtion-text">You will be billed a base fee of $99/month if you have between 0 to 10 <br /> active patients(SADC, HHC)/employees (Staffing) per month and $10 per active  <br />patients(SADC, HHC)/employees (Staffing) if you have between 11 and 500.  </Text>
                </div>

              </Col>
              <Col className="payment-sidebar">
                {options.clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <Checkout />
                </Elements>
                )}
              </Col>
            </Row>
          </Container>)
        }

        { (paymentStatus === 1)  &&

          <Container className="payment-container">
            <Row>
              <section>
                <div>
                  <img alt="sucess-payment" src={successImage} className="payment-status-image"/> 
                </div>
                <div>
                  <Text className="payment-status-title" thin>Thank You! Your Payment was successful.</Text>
                  <Text thin>Please check your email inbox for a confirmation email regarding your recent purchase.</Text>
                  <Text thin>Please give it up to 15 minutes to arrive (make sure you check your Spam Folder if you have not received it).</Text>
                  <Text thin>If you have any questions or concerns feel free to <a href="/#contact">contact support here.</a></Text> 
                  <Text thin>Thank you so much for doing business with us.</Text>
                </div>
              </section>
            </Row>
          </Container>
        }

        { (paymentStatus === 2)  &&

          <Container className="payment-container">
            <Row>
              <section>
                <div>
                  <img alt="pending-payment" src={pendingImage} className="payment-status-image"/> 
                </div>
                <div>
                  <Text className="payment-status-title" thin>Thank You! Your Payment is processing.</Text>
                  <Text thin>Please check your email inbox for a confirmation email regarding your recent purchase.</Text>
                  <Text thin>Please give it up to 15 minutes to arrive (make sure you check your Spam Folder if you have not received it).</Text>
                  <Text thin>If you have any questions or concerns feel free to <a href="/#contact">contact support here.</a></Text> 
                  <Text thin>Thank you so much for doing business with us.</Text>
                </div>
              </section>
            </Row>
          </Container>
        }

        { (paymentStatus === -1)  &&

          <Container className="payment-container">
            <Row>
              <section>
                <div>
                  <img alt="failed-payment" src={failedImage} className="payment-status-image"/> 
                </div>
                <div>
                  <Text className="payment-status-title" thin>Sorry. Your Payment failed.</Text>
                  <Text thin>Sorry, your payment failed. No charges were made.</Text>
                  <Text thin>Please feel free to retry the payment with a different card.</Text>
                  <Text thin>If you have any questions or concerns feel free to <a href="/#contact">contact support here.</a></Text> 
                </div>
              </section>
            </Row>
          </Container>
        }

        { (paymentStatus === -2)  &&

          <Container className="payment-container">
            <Row>
              <section>
                <div>
                  <img alt="failed-payment" src={invalidImage} className="payment-status-image"/> 
                </div>
                <div>
                  <Text className="payment-status-title" thin>Oops!!. It's an expired transaction.</Text>
                  <Text thin>Sorry, This transaction has been expired. Invalid Payment ID found</Text>
                  <Text thin>If you think this is a wrong information Please <a href="/#contact">contact support here.</a></Text> 
                </div>
              </section>
            </Row>
          </Container>
        }
      </>
  );
}

export default Stripeo;