import { Col, Container, Row } from "react-bootstrap";
import Logo from './images/ontime-login-invert.png';
import './footer.css';
import './footer-sm.css';
import './footer-md.css';
import Text from "../../views/text/text";

function Footer() {
    return (
        <>
            <Container fluid className="footer-container">
                <Row className="footer-main d-flex justify-content-center">
                    <Col md="19" lg="3" className="footer-col footer-logo">
                        <section><a href={window.location.origin}><img src={Logo}  alt="ontime logo"/> </a></section>
                        <section><Text thin className="white-text">Unlock Your Technology with Ontime</Text></section>
                    </Col>
                    <Col md="5" lg="3" className="footer-col">
                        <section><Text className="white-text" heavy>Useful Links</Text></section>
                        <section className="footer-links footer-text">
                            <ul>
                                <li><a href=".">Get a Demo</a></li>
                                <li><a href=".">Privacy Policy</a></li>
                                <li><a href=".">Cookies Policy</a></li>
                                <li><a href="https://www.aaidebook.com/blog/" target="_blank" rel="noreferrer">Investors Relations</a></li>
                            </ul>
                        </section>
                    </Col>
                    <Col md="4" lg="3" className="footer-col">
                        <section><Text className="white-text" heavy>Our Address</Text></section>
                        <section>
                            <Text className="white-text footer-text">Ontime ITS
                                   < br/> 1960 NE 47 th Street, Suite 100
                                   < br/> Fort Lauderdale, FL 33308
                                   < br/>P: 954-727-1365
                                   < br/>
                            </Text>
                        </section>
                    </Col>
                </Row>

                <Row className="footer-main copyright-text justify-content-center">
                    <Col md={{span:4, order:2}} lg={{span:3, order:1}}><Text className="white-text footer-text"> Copyright ©2025 All rights reserved </Text></Col>
                    <Col md={{span:3, order:1}} lg={{span:3, order:2}}></Col>
                    <Col md={{span:3, order:3}} lg={{span:3, order:3}}></Col>
                </Row>
            </Container>
        </>
    );
}

export default Footer;