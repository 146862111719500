import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './../../index.css';
import './Register.css';
import Header from '../../sections/header/header';
import Footer from '../../sections/footer/footer';
import RegisterForm from '../../sections/register-form/RegisterForm';

const registerPage =
    <>
      <Header></Header>
      <div className="content">
        <RegisterForm/>
      </div>
      <Footer></Footer>
    </>


function Register() {
  return (registerPage);
}

export default Register;