import './input.css';
import Overlay from "react-bootstrap/Overlay";
import { useRef } from 'react';

function Input(props) {
    const target = useRef(null);

    var output;
    if (props.type !== 'textarea') {
        output = <input type={props.type} placeholder={props.placeholder} {...props}></input>;
    } else {
        output = <textarea type={props.type} placeholder={props.placeholder} {...props} rows="4"></textarea>
    }

    const errorPop = (message) => {

       return (
        <Overlay target={target.current} placement="right" show={props.showerror === 1? true: false }>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div {...props} className="error-overlay" style={{ ...props.style }} >
                { message }
            </div> 
        )}
        </Overlay>
       )
    }
    
    return (
        <> 
          { output }
          <div ref={target} className="overlay-positioner">
          { errorPop(props.errormessage) }
          </div>
        </>
    );
}

export default Input;