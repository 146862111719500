import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import Input from '../../views/input/input';
import Text from '../../views/text/text';
import Title from '../../views/title/title';
import Button from '../../views/button/button';
import './RegisterForm.css'
import DemoImage from './images/subscription_registration.png'; 
import { useState } from 'react';
import validator from '../../core/utils/validators';
import AxiosClient from '../../core/rest/client';
import { useNavigate } from 'react-router-dom';
import Progress from '../progress/progress';
import ResultDialog from '../../views/result-dialog/result-dialog';
import toast from 'react-hot-toast';

function RegisterForm() {

    let navigate = useNavigate();
    const [inputVals, setInputVals] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        orgName: '',
        orgType: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        taxId: '',
        websiteUrl: ''
    });

    const [errorState, setErrorState] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        orgName: false,
        orgType: false,
        taxId: false,
        address2: false,
        address1: false,
        city: false,
        state: false,
        websiteUrl: false,
        zipCode: false
    });

    const [showError, setShowError] = useState(false);
    const [isInSubmitProgress, setIsInSubmitProgress] = useState(false);

    const demoSubmitFunction = () => {

        if (! document.getElementsByName("termscond")[0].checked) {
            return;
        }
        if(isInSubmitProgress)
            return;

        for (var key in inputVals) {
            if (key === 'firstName' || key === 'lastName') {
                errorState[key] = !validator['name'](inputVals[key]);
            } else if (key === 'email') {
                errorState[key] = !validator['email'](inputVals[key]);
            } else if (key === 'phone') {
                errorState[key] = !validator['phone'](inputVals[key]);
            } else if (key === 'orgName') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'taxId') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'address1') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'city') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'state') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'zipCode') {
                errorState[key] = !validator['zip'](inputVals[key]);
            } else if (key === 'orgType') {
                errorState[key] = inputVals[key] === '';
            }
        }
        
        setErrorState({ 
            ...errorState
        });
        for (key in errorState) {
            if (errorState[key]) {
                return;
            }
        }

        setIsInSubmitProgress(true);

        let axiosClient = new AxiosClient();
        axiosClient.postRequest(process.env.REACT_APP_API_URL +"/payments", inputVals, onSubmissionSuccess, onSubmissionFailure);
    };

    const onSubmissionSuccess = (response) => {
        setInputVals({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            orgName: '',
            orgType: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            taxId: '',
            websiteUrl: ''
        });
        setIsInSubmitProgress(false);
        
        if (response.data === undefined) {
            setShowError(true);
            toast.error("Failed to complete the registration");
            return;
        }

        const paymentSecret = response.data.key;
        navigate('/register/payment', { state: { clientSecret: paymentSecret }});
    };

    const onSubmissionFailure = (error) => {
        setShowError(true);
        setInputVals({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            orgName: '',
            orgType: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            taxId: '',
            websiteUrl: ''
        });
        setIsInSubmitProgress(false);
        toast.error("Failed to complete the registration");
    };

    const changeHandler = (e) => {
        inputVals[e.target.name] = e.target.value;
        if(errorState[e.target.name] !== undefined) {
            errorState[e.target.name] = false;
            setErrorState({ 
                ...errorState
            });
        }
    };

    const validateFn = (e) => {
        errorState[e.target.name] = !validator[e.target.attributes['data-validation-type'].value](e.target.value);
        setErrorState({ 
            ...errorState
        });
    };

    return (
        <>
            <Container className="demo-container">
                <Row>
                    <section><Title>Let's get started with Ontime<strong>Register Now!</strong></Title></section>
                </Row>
                <Row className="demo-form-container">
                    <Col sm="5">
                        <Row>
                        { showError &&
                            <Alert  variant="danger">
                                Oh snap! You got an error!
                            </Alert>
                        }
                        </Row>
                        <Row>
                            <section><Text>Your Name</Text></section>
                        </Row>
                        <Row className="demo-form-row">
                            <Col sm="12" lg="6">
                                <Input placeholder="First Name" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="firstName" errormessage="Please enter a valid First Name"
                                        showerror={+errorState.firstName} data-validation-type="name" value={inputVals.firstName}></Input>
                            </Col>
                            <Col sm="12" lg="6" className="mt-2 mt-lg-0">
                                <Input placeholder="Last Name" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="lastName" errormessage="Please enter a valid Last Name"
                                        showerror={+errorState.lastName} data-validation-type="name" value={inputVals.lastName}></Input>
                            </Col>
                        </Row>
                        <Row className="demo-form-row">
                            <section><Text>Billing Address</Text></section>
                        </Row>
                        <Row className="demo-form-row">
                            <Col sm="12">
                                <Input placeholder="Email Address" type="email" onChange={changeHandler} onBlur={validateFn}
                                        name="email" errormessage="Please enter valid Email address" 
                                        showerror={+errorState.email} data-validation-type="email" value={inputVals.email}></Input>
                                </Col>
                            <Col sm="12" className="extra-margin">
                                <Input placeholder="Phone Number" type="telephone" onChange={changeHandler} onBlur={validateFn}
                                        name="phone" errormessage="Please enter a valid Phone Number" 
                                        showerror={+errorState.phone} data-validation-type="phone" value={inputVals.phone}></Input>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Input placeholder="Address Line 1" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="address1" errormessage="Please enter a valid Address Line" 
                                        showerror={+errorState.address1} data-validation-type="value" value={inputVals.address1}></Input>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Input placeholder="Address Line 2" type="text" onChange={changeHandler} 
                                        name="address2"
                                        value={inputVals.address2}></Input>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Input placeholder="City" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="city" errormessage="Please enter a valid City" 
                                        showerror={+errorState.city} data-validation-type="value" value={inputVals.city}></Input>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Form.Select size="lg" onChange={changeHandler} name="state">
                                        <option value="">Select State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                 </Form.Select>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Input placeholder="Zip Code" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="zipCode" errormessage="Please enter a valid Zip Code" 
                                        showerror={+errorState.zipCode} data-validation-type="zip" value={inputVals.zipCode}></Input>
                            </Col>
                        </Row>
                        <Row className="demo-form-row">
                            <section><Text>Organization Information</Text></section>
                        </Row>
                        <Row className="demo-form-row">
                            <Col sm="12">
                                <Input placeholder="Organization Name" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="orgName" errormessage="Please enter your Organization Name"
                                        showerror={+errorState.orgName} data-validation-type="text" value={inputVals.orgName}></Input>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Input placeholder="EIN /Tax Id Number" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="taxId" errormessage="Please enter Tax Id Name"
                                        showerror={+errorState.taxId} data-validation-type="text" value={inputVals.taxId}></Input>
                            </Col>
                            <Col sm="12"className="extra-margin">
                                <Input placeholder="Website URL" type="text" onChange={changeHandler} 
                                        name="websiteUrl"
                                        value={inputVals.websiteUrl}></Input>
                            </Col>
                            <Col sm="12" className="extra-margin">
                                <Form.Select size="lg" onChange={changeHandler} name="orgType" isInvalid={errorState.orgType}>
                                    <option value="">Select Org Type</option>
                                    <option value="HSA">Healthcare Staffing Agency</option>
                                    <option value="SADC">Social Adult Day Care Center</option>
                                    <option value="HHA">Home Healthcare Agency </option>
                                </Form.Select>
                            </Col>

                            <Col sm="12" className="extra-margin tnc-container">
                                <br />
                                <input type="checkbox" className="checkbox" name="termscond" />
                                <label className='termsnconditions'>I've read and I agree to the  <a target="_blank" href='/ontime-ua.pdf' rel="noopener noreferrer">Terms and Conditions</a></label>
                            </Col>
                        </Row>
                        <Row className="demo-form-row">
                            <Col>
                                <Button className="float-lg-end" large onClick={ demoSubmitFunction }>Submit</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm="5" className='imageSection'>
                        <img src={DemoImage} alt="demo"></img>  
                    </Col>
                </Row>
                <ResultDialog></ResultDialog>
            </Container>
            <Progress show = {isInSubmitProgress}></Progress>
        </>
    );
}

export default RegisterForm;