import './title.css'

function Title(props) {
    var classes = "";
    classes = props.type === 'sub' ? 'subtitle': 'title';
    return (
        <>
            <span className={classes}>{props.children}</span>
        </>
    );
}

export default Title;