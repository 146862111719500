import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import Input from '../../views/input/input';
import Text from '../../views/text/text';
import Title from '../../views/title/title';
import Button from '../../views/button/button';
import './DemoForm.css'
import DemoImage from './images/demo-image.png'; 
import { useState } from 'react';
import validator from '../../core/utils/validators';
import AxiosClient from '../../core/rest/client';
import Progress from '../progress/progress';
import toast from 'react-hot-toast';
import ResultDialog from '../../views/result-dialog/result-dialog';
import VideoBox from '../../views/player/videobox';


const videos = {
    HSA : '0cLk-EpOXSM',
    HHA : '7AvHWWpjMVk',
    SADC : 'Cd1AiDGCD2c'
}

function DemoForm() {

    const [inputVals, setInputVals] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        orgName: '',
        orgType: ''
    });

    const [errorState, setErrorState] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        orgName: false,
        orgType: false
    });

    const getUrl = (video) => {
        const url = "https://www.youtube.com/embed/"+ videos[inputVals.orgType] + "?controls=0&loop=1&autoplay=1&rel=0&playsinline=1";
        return url;
    }
    const [showError, setShowError] = useState(false);
    const [sucessSubmit, setSuccessSubmit] = useState(false);
    const [isInSubmitProgress, setIsInSubmitProgress] = useState(false);
    const [url, setUrl] = useState(getUrl('HSA'))

    

    const demoSubmitFunction = () => {
        if(isInSubmitProgress)
            return;

        for (var key in inputVals) {
            if (key === 'firstName' || key === 'lastName') {
                errorState[key] = !validator['name'](inputVals[key]);
            } else if (key === 'email') {
                errorState[key] = !validator['email'](inputVals[key]);
            } else if (key === 'phone') {
                errorState[key] = !validator['phone'](inputVals[key]);
            } else if (key === 'orgName') {
                errorState[key] = !validator['value'](inputVals[key]);
            } else if (key === 'orgType') {
                errorState[key] = inputVals[key] === '';
            }
        }
        
        setErrorState({ 
            ...errorState
        });
        for (key in errorState) {
            if (errorState[key]) {
                return;
            }
        }

        setIsInSubmitProgress(true);

        let axiosClient = new AxiosClient();
        axiosClient.postRequest(process.env.REACT_APP_API_URL +"/demo-request", inputVals, onSubmissionSuccess, onSubmissionFailure);
    };

    const onSubmissionSuccess = (response) => {
        
        
        if (response.data === undefined) {
            toast.error("Failed to submit demo request");
            setShowError(true);
            return;
        }

        toast.success("Successfully submitted the request for demo");

        if (response.data.status !== undefined && response.data.status === '1000') {
            setUrl(getUrl(videos[inputVals.orgType]))
            setSuccessSubmit(true);
        }

        setInputVals({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            orgType: '',
            orgName: ''
        });
        setIsInSubmitProgress(false);
    };

    const onSubmissionFailure = (error) => {
        setShowError(true);
        setInputVals({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            orgName: '',
            orgType: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            taxId: '',
            websiteUrl: ''
        });
        setIsInSubmitProgress(false);
        toast.error("Failed to submit demo request");
    };

    const changeHandler = (e) => {
        inputVals[e.target.name] = e.target.value;
        if(errorState[e.target.name] !== undefined) {
            errorState[e.target.name] = false;
            setErrorState({ 
                ...errorState
            });
        }
    };

    const validateFn = (e) => {
        errorState[e.target.name] = !validator[e.target.attributes['data-validation-type'].value](e.target.value);
        setErrorState({ 
            ...errorState
        });
    };

    
    return (
        <>
            <Container className="demo-container">
                <Row>
                    <section><Title>You can learn more about OnTime by<strong>getting a demo!</strong></Title></section>
                    { !sucessSubmit && <section><Text thin>Fill out this form to get access to the pre-recorded demo. </Text></section> }
                    { sucessSubmit && <section><Text thin>We have sent a copy of our demo to the email address you provided. Please make sure to check your promotions folder.</Text></section> }
                </Row>
                { !sucessSubmit && <Row className="demo-form-container">
                    <Col lg="5" md="8">
                        <Row>
                        { showError &&
                            <Alert  variant="danger">
                                Oh snap! You got an error!
                            </Alert>
                        }
                        </Row>
                        <Row>
                            <section><Text>Your Name</Text></section>
                        </Row>
                        <Row className="demo-form-row">
                            <Col sm="12" md="12" lg="6">
                                <Input placeholder="First Name" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="firstName" errormessage="Please enter a valid First Name"
                                        showerror={+errorState.firstName} data-validation-type="name" value={inputVals.firstName}></Input>
                            </Col>
                            <Col sm="12" md="12" lg="6" className='mt-3 mt-lg-0'>
                                <Input placeholder="Last Name" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="lastName" errormessage="Please enter a valid Last Name"
                                        showerror={+errorState.lastName} data-validation-type="name" value={inputVals.lastName}></Input>
                            </Col>
                        </Row>
                        <Row className="demo-form-row">
                            <section><Text>Contact Information</Text></section>
                        </Row>
                        <Row className="demo-form-row">
                            <Col sm="12">
                                <Input placeholder="Email Address" type="email" onChange={changeHandler} onBlur={validateFn}
                                        name="email" errormessage="Please enter valid Email address" 
                                        showerror={+errorState.email} data-validation-type="email" value={inputVals.email}></Input>
                                </Col>
                            <Col sm="12" className='mt-3'>
                                <Input placeholder="Phone Number" type="telephone" onChange={changeHandler} onBlur={validateFn}
                                        name="phone" errormessage="Please enter a valid Phone Number" 
                                        showerror={+errorState.phone} data-validation-type="phone" value={inputVals.phone}></Input>
                            </Col>
                        </Row>
                        <Row className="demo-form-row">
                            <section><Text>Organization Information</Text></section>
                        </Row>
                        <Row className="demo-form-row">
                            <Col sm="12">
                                <Input placeholder="Organization Name" type="text" onChange={changeHandler} onBlur={validateFn}
                                        name="orgName" errormessage="Please enter your Organization Name"
                                        showerror={+errorState.orgName} data-validation-type="text" value={inputVals.orgName}></Input>
                            </Col>
                            <Col sm="12" className='mt-3'>
                                <Form.Select size="lg" name="orgType" onChange={changeHandler} isInvalid={errorState.orgType}>
                                    <option value="">Select Org Type</option>
                                    <option value="HSA">Healthcare Staffing Agency</option>
                                    <option value="SADC">Social Adult Day Care Center</option>
                                    <option value="HHA">Home Healthcare Agency </option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="demo-form-row">
                            <Col>
                                <Button className="float-lg-end" large onClick={ demoSubmitFunction }>Submit</Button>
                            </Col>
                        </Row>
                        
                    </Col>
                    <Col md="8" lg="5" className='imageSection d-none d-lg-block'>
                        <img src={DemoImage} alt="demo"></img>  
                    </Col>
                </Row>  }
                { sucessSubmit && 
                <Row className='demo-video-container'>
                   <VideoBox url={url}></VideoBox> 
                </Row> }
                <ResultDialog></ResultDialog>
            </Container>
            <Progress show = {isInSubmitProgress}></Progress>
        </>
    );
}

export default DemoForm;

