import {useState } from 'react';
import { Modal } from 'react-bootstrap';
import ImageButton from '../image-button/image-button';
import './player.css'
import VideoBox from './videobox';

function Player(props) {
    const [show, setShow] = useState(false);

    const url = "https://www.youtube.com/embed/"+ props.video + "?controls=0&loop=1&autoplay=1&rel=0&playsinline=1";

    return(
        <>
            <Modal show={show} centered size="xl" onHide={() => setShow(false)}> 
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <VideoBox url={url}></VideoBox>
                </Modal.Body>
            </Modal>
           {props.type === 'icon' && <div className="video-btn video-btn-pos"> 
                <span className="play-btn popup-youtube" onClick={() => setShow(true)}>
                    <i className="far fa-play-circle"></i>
                </span>
                <div className="spinner-eff">
                    <div className="spinner-circle circle-1"></div>
                    <div className="spinner-circle circle-2"></div>
                </div>
            </div>}
            {props.type === 'button' && <ImageButton onClick={() => setShow(true) }><span className="icon"></span><span>See Video</span></ImageButton>}
        </>
    )
}

export default Player;