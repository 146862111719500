import './image-button.css';

function ImageButton(props) {
    var classes = "ripple image-button ";
    
    var onClickFunction = undefined;

    if (props.href) {
        onClickFunction = () => {window.location.href=props.href};
    } else if (props.onClick) {
        onClickFunction = props.onClick;
    }

    return (
        <button className={classes} onClick={ onClickFunction }><span className='button-play-icon'></span>{props.children}</button>
    );
}

export default ImageButton;