import { useEffect, useRef } from 'react';

function VideoBox(props) {
    
    const ref = useRef(null);
    useEffect(() => {
        let width = ref.current ? ref.current.offsetWidth : 0;
        if (width > 0)
            ref.current.height = width * (62/111);
    });

    return (
        <>
            <iframe ref={ref} width="100%" src={props.url} allow="autoplay; fullscreen" title="video"></iframe>
        </>
    )
}

export default VideoBox;