import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Register from './pages/register/Register';
import Payment from './pages/payment/Payment';
import Demo from './pages/demo/Demo';

const index = 
    <React.StrictMode>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home/>}></Route>
            <Route path='register' element={ <Register/>}></Route> 
            <Route path='register/payment' element={ <Payment/>}></Route>  
            <Route path='demo' element={ <Demo/>}></Route>  
          </Routes> 
         </BrowserRouter>
    </React.StrictMode>;

ReactDOM.render(index, document.getElementById('root'));
reportWebVitals();