import { Toaster } from 'react-hot-toast';
function ResultDialog(props) {
    return (
        <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
                // Define default options
                duration: 5000
            
                // // Default options for specific types
                // success: {
                //   duration: 3000,
                //   theme: {
                //     primary: 'green',
                //     secondary: 'black',
                //   },
                }}
        />
    )
}

export default ResultDialog;