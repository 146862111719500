import { Container, Navbar, Nav, Dropdown } from "react-bootstrap";
import './header.css';
import './header-sm.css';
import './header-md.css';
import Button from '../../views/button/button';
import Logo from './images/ontime-logo.png'
import { useEffect, useState } from "react";

function Header(props) {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        window.onscroll = () => {
            if (props.home) {
                setOffset(window.pageYOffset);
            }
        }
    }, [props.home]);
    return (
        <>
            <Navbar expand="lg" className="ontime-navbar shadow-sm" sticky="top" collapseOnSelect>
                <Container>
                    <Navbar.Brand href="/"> <img src={Logo} alt="ontime logo" /> </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {props.home &&
                                <>
                                    <Nav.Link href="#home" active={offset < 80 ? true : false}>Home</Nav.Link>
                                    <Nav.Link href="#products" active={offset > 80 && offset < 1650 ? true : false}>Products</Nav.Link>
                                    <Nav.Link href="#pricing" active={offset > 1650 && offset < 2450 ? true : false}>Pricing</Nav.Link>
                                    <Nav.Link href="#contact" active={offset > 2450 ? true : false}>Contact Us</Nav.Link>
                                    <Nav.Link href="https://www.passiveworkforce.com/" target={"_blank"} active={offset > 2450 ? true : false} className="consulting">Consulting</Nav.Link>
                                </>
                            }
                            {
                                !props.home &&
                                <>
                                    <Nav.Link href={window.location.origin}>Home</Nav.Link>
                                    <Nav.Link href={window.location.origin + "#features"}>Products</Nav.Link>
                                    <Nav.Link href={window.location.origin + "#pricing"}>Pricing</Nav.Link>
                                    <Nav.Link href={window.location.origin + "#contact"}>Contact Us</Nav.Link>
                                    <Nav.Link href="https://www.passiveworkforce.com/" target={"_blank"} active={offset > 2450 ? true : false} className="consulting">Consulting</Nav.Link>
                                </>
                            }
                        </Nav>
                        <section className="nav-btns-small">
                            <Dropdown.Divider />
                            <Nav.Link href="https://app.ontimeits.com/"><b>Login</b></Nav.Link>
                            <Nav.Link href="/register">Sign Up</Nav.Link>
                        </section>
                        
                        <Nav className="nav-btns">
                            <Button href="https://app.ontimeits.com/">Login</Button>
                            <Button borderless href="/register">Sign Up</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;